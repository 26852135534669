import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Card,
  createStyles,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { CustomTheme } from "../../../../../types/customTheme";
import { da } from "date-fns/locale";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    cardStyleEven: {
      height: "40px",
      boxShadow: "none",
      "&:nth-of-type(n)": {
        backgroundColor: theme.palette.background.entity_background,
      },
    },
    cardStyleOdd: {
      height: "40px",
      boxShadow: "none",
      "&:nth-of-type(n)": {
        backgroundColor: theme.palette.background.paper,
      },
    },
    cardStyleRed: {
      height: "40px",
      boxShadow: "none",
      "&:nth-of-type(n)": {
        backgroundColor: theme.palette.custom.red.main,
      },
    },
    cardStyleFooter: {
      height: "40px",
      boxShadow: "none",
      "&:nth-of-type(n)": {
        backgroundColor: theme.palette.custom.green.main,
      },
    },
    cardStylePurple: {
      height: "40px",
      boxShadow: "none",
      "&:nth-of-type(n)": {
        backgroundColor: theme.palette.custom.blue.main,
      },
    },
  }),
);

export interface AvailabilitySelectionModalProps {
  shiftReportNode: any;
}

/* This creates the banking and summery tables. This component only works if the key is banking or summery. */
const BankingTakings: React.FunctionComponent<
  AvailabilitySelectionModalProps
> = ({ shiftReportNode }) => {
  const [nodes, setNodes] = useState<any>([]);

  useEffect(() => {
    setNodes(shiftReportNode);
  }, [shiftReportNode]);

  const handleTooltip = (type: any) => {
    if (type === "Cash") {
      return "This cash total includes only in-store walk-in cash sales and excludes any online orders paid in cash. Online orders will be reported separately under their respective platforms.";
    } else if (type === "Card") {
      return "This card total includes only in-store walk-in card sales and excludes any online orders paid by card. Online orders will be reported separately under their respective platforms.";
    }
  }

  console.log("Asdfaf87asafsdf", shiftReportNode);
  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  return (
    <div style={{ marginTop: "30px" }}>
      <Card
        style={{
          borderRadius: "10px",
        }}
      >
        {!_.isEmpty(nodes) &&
          nodes.map((data: any, index: any) => (
            <div
              className={
                nodes.length === index + 1
                  ? classes.cardStyleFooter
                  : index % 2 === 0
                  ? classes.cardStyleEven
                  : classes.cardStyleOdd
              }
            >
              <Grid container style={{ placeItems: "center", height: "40px" }}>
                <Grid
                  item
                  xs={6}
                  style={{ paddingLeft: "16px", fontWeight: "bold" }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    {console.log("sdfsgfdg34asdasf", data.type)}
                    {data.type}
                    {(data.type === "Cash" || data.type === "Card") && (
                      <Tooltip title={handleTooltip(data.type) || ""}>
                        <InfoIcon
                          style={{
                            marginLeft:
                              data.Title === "Card Payments Online" ||
                              data.Title === "Cash Payments Online"
                                ? "21px"
                                : "10px",
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingRight: "16px",
                    display: "flex",
                    justifyContent: "end",
                    color: "white",
                  }}
                >
                  {data.amount}
                </Grid>
              </Grid>
            </div>
          ))}
      </Card>
    </div>
  );
};

export default BankingTakings;
